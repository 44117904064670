import React, { Suspense, lazy } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import FallBackItem from './components/FallBack';
// import thTh from 'antd/lib/locale/';
import moment from 'moment';

import './scss/style.scss';
import 'antd/dist/antd.less';

moment.locale('th');

const Login = lazy(() => import('./views/Pages/Login'));
const DefaultLayout = lazy(() => import('./containers'));

const App = () => {
  return (
    <ConfigProvider>
      <Router>
        <Suspense fallback={<FallBackItem />}>
          <Switch>
            <Route
              exact
              path='/login'
              name='Login'
              component={(props) => <Login {...props} />}
            />
            <Route
              path='/'
              name='Home'
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </Suspense>
      </Router>
    </ConfigProvider>
  );
};

export default App;
